<template>
  <div class="leadeboard">
    
    <div class="podium" v-if="showLeadeboard">

      <div v-if="scores.length > 0">
        <h3 class="is-size-3 mb-5">Leadeboard</h3>
        <div class="others mt-3">
            <div v-for="(entry,index) in scores" :key="entry.id" class="item">
              <!-- <div class="rank">{{ $t('exp_trivia.ordinal_n',[index + 1]) }}</div> -->
              <div class="rank">{{ index + 1 }}</div>
              <player-avatar :player="entry.user" :scale="2" :showUsername="false" />
              <span class="username">{{ entry.user.username }}</span>
              <span class="score">{{ Math.round(entry.score) }}</span>
            </div>
            <!-- <div v-for="i in (10 - scores.length)" :key="`placeholder-${i}`" class="item">
              <div class="rank">{{ scores.length + i }}</div>
            </div> -->
        </div>
      </div>
      <div v-else>
          <h3 class="is-size-3 mt-5">¡Poné a prueba tus conocimientos en esta trivia!</h3>
      </div>
    </div>
    
  </div>

</template>

<script>
import axios from 'axios';
import qs from 'qs'

export default {
  components: {
  },
  data() {
    return {
      selected: {},
      scores: []
    }
  },
  props: {
    period: {
      type: String,
      required: false,
      default: 'allTime',
    },
    maxLength: {
      type: Number,
      required: false,
      default: 10,
    }
  },
  methods: {
    getPeriodDate(period) {
      const periodMap = {
        today: this.today,
        last7days: this.lastDays(7),
        thisWeek: this.thisWeek,
        last30days: this.lastDays(30),
        thisMonth: this.thisMonth
      };
      return periodMap[period] ? periodMap[period]() : null;
    },
    lastDays(days = 7) {
      const now = new Date();

      const oneWeekAgoDate = new Date(now.setDate(now.getDate() - days));

      return oneWeekAgoDate.toISOString();
    },
    today() {
      const now = new Date();
      now.setHours(0, 0, 0, 0); // Set to start of the day
      return now.toISOString();
    },
    thisWeek(weekStartAt = 1) {
      const now = new Date();

      const dayOfWeek = now.getDay(); // 0 (Sunday) to 6 (Saturday)
      const startOfWeek = new Date(now);
      startOfWeek.setDate(now.getDate() - dayOfWeek + weekStartAt);
      startOfWeek.setHours(0, 0, 0, 0); // Set to start of the day

      return startOfWeek.toISOString();
    },
    thisMonth() {
      const now = new Date();

      const firstDayOfThisMonth = new Date(now.getFullYear(), now.getMonth(), 1);
      firstDayOfThisMonth.setHours(0, 0, 0, 0);
      
      return firstDayOfThisMonth.toISOString();
    }
  },
  computed: {
    showLeadeboard () {
      return this.$store.state.space.players.length == 0
    }
  },
  watch: {

  },
  async mounted() {

    let config = this.$store.getters["space/config"];

    let andConditions = [
      { 'experience.id': config.experience.id },
      { 'space.id': config.space.id },
      { 'user.role': { equals: 'visitor' } },
      { score: { greater_than: 0 } }
    ];

    const periodDate = this.getPeriodDate(this.period);

    if (periodDate) {
      andConditions.push({ 'created_at': { greater_than: periodDate } });
    }

    const stringifiedQuery = qs.stringify(
      {
        where: {
          and: andConditions
        },
        limit: 0,
        sort: '-score'
      },
      { addQueryPrefix: true },
    )

    let query = `${process.env.VUE_APP_API_BASE}/api/scores${stringifiedQuery}`;

    const res = await axios.get(query);
    const scores = res.data.docs;
    const uniqueUsers = new Set();
    const topScores = [];

    for (const entry of scores) {
        if (!uniqueUsers.has(entry.user.id)) {
            uniqueUsers.add(entry.user.id);
            topScores.push(entry);
        }
        if (topScores.length === this.maxLength) {
            break;
        }
    }

    this.scores = topScores;

  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';
@import '@/styles/mixins.scss';

.leadeboard {
  position: absolute;
  left: 0;
  top: 0;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  color: white;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

.podium {
  display: flex;
  flex-direction: column;
  padding: 42px;
  min-height: 450px;
  width: 600px;
  @include blurBox();

  h3 {
    text-align: center;
    font-weight: bold;
    margin-top: -1.4rem;
  }

  .user {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .username {
    width: 142px;
    text-transform: uppercase;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

}

.others {

  width: 100%;

  .item {
    color: $black-bis;
    height: 70px;
    background: #FAFAFA88;
    border-radius: $general-border-radius;
    display: flex;
    align-content: center;
    align-items: center;
    gap: 1em;
    padding-inline: 18px;
    margin-bottom: 8px;
    width: 100%;

    .rank {
      font-size: 20px;
      font-weight: bold;
      width: 1rem;
      text-align: right;
      
    }

    .username {
      flex-grow: 1;
      text-transform: uppercase;
      font-size: 20px;
    }

    .score {
      flex-shrink: 0;
      font-weight: bolder;
      font-size: 20px;
    }
  }
}
}
</style>